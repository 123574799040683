<template>
  <template v-if="app">
    <img
      v-if="app.logo"
      class="logo"
      :alt="$t('ui.logo')"
      :src="require(`@/assets/${app.logo}`)"
    >
    <h1 class="title">
      {{ app.title }}
    </h1>
    <p v-html="$t(app.description)" />
    <form
      v-if="showForm"
      @submit.prevent="sendResetLink"
    >
      <BaseInput
        id="email"
        v-model.trim="email"
        type="email"
        required
        autofocus
        autocomplete="email"
        :label="$t('ui.email')"
      />
      <BaseButton type="submit">
        {{ $t('ui.sendLink') }}
      </BaseButton>
    </form>
  </template>
  <BaseInfobox
    v-if="msg"
    :type="infoboxType"
    :remove-margins="!app"
    v-html="msg"
  />
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseInfobox from '@/components/BaseInfobox.vue'
import ResetService from '@/services/ResetService.js'
import { setLocale } from '@/util/i18n.js'

export default {
  name: 'ResetPage',
  components: {
    BaseInput,
    BaseButton,
    BaseInfobox
  },
  data () {
    return {
      app: null,
      msg: '',
      infoboxType: '',
      email: this.$route.params.email
    }
  },
  computed: {
    showForm () {
      return !(this.msg && this.infoboxType === 'success')
    }
  },
  async created () {
    setLocale(this.$route.query.lang)

    const response = await ResetService.getApp(this.$route.params.appId)
    this.app = response.app
    if (response.errorMsg) {
      this.infoboxType = 'error'
      this.msg = this.$t(response.errorMsg)
    }
  },
  methods: {
    /* VM Funktionen */
    async sendResetLink () {
      const data = {
        email: this.email,
        appId: this.$route.params.appId,
        domain: window.location.host
      }

      const response = await ResetService.sendResetLink(data)
      this.infoboxType = response.hasError ? 'error' : 'success'

      /* In gewissen Serverantworten müssen dynamisch Werte gesetzt werden */
      const translationVars = {
        appId: data.appId,
        email: data.email,
        lang: this.$i18n.locale
      }

      this.msg = this.$t(response.msg, translationVars)
    }
  }
}
</script>
