import http from '@/services'
import i18n from '@/i18n.js'

export default {
  async getApp (appId) {
    try {
      const response = await http.get(`reset.cfc?method=getApp&appId=${appId}&lang=${i18n.global.locale}`)
      return {
        app: response.data.app,
        errorMsg: ''
      }
    } catch (error) {
      return {
        app: null,
        errorMsg: error?.response?.data?.errorMsg || 'serverError.unknownServerError'
      }
    }
  },
  async sendResetLink (data) {
    const dataWithLang = {
      ...data,
      lang: i18n.global.locale
    }
    try {
      const response = await http.post('reset.cfc?method=sendResetLink', dataWithLang)
      return {
        hasError: false,
        msg: response.data.msg
      }
    } catch (error) {
      return {
        hasError: true,
        msg: error?.response?.data?.msg || 'serverError.unknownServerError'
      }
    }
  }
}
